import React from 'react';
import M from 'materialize-css';

import './login.css'
import {cacheHeaders, basePath} from '../config';

class Login extends React.Component{

    state={
        userEmail: '',
        password: '',
    }

    inputChange = (event) => {
        let value = event.target.value;
        this.setState({[event.target.name]: value});
    }

    loginHandler = (event) => {
        event.preventDefault();
        let postData = {
            email: this.state.userEmail,
            password: this.state.password
        }
        fetch(basePath + '/login/',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...cacheHeaders
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.json())
        .then(data => {
            if(data.success){
                localStorage.setItem('authToken',data.authToken);
                localStorage.setItem('role',data.role);
                this.props.authenticate(true,data.role);
                this.props.history.push('/dashboard');
            }
            else M.toast({html: data.message, classes: 'rounded ToastFailure'});
        })
    }

    componentDidMount(){
        localStorage.removeItem('authToken');
        this.props.authenticate(false);
    }

    render(){
        return(
            <div className='LoginForm card-panel'>
                <form onSubmit={this.loginHandler}>
                    <h4>Login</h4>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="userEmail" name='userEmail' type="email" value={this.state.userEmail} onChange={this.inputChange} required autoComplete='off' className="validate" />
                            <label className={this.state.userEmail !== '' ? 'active' : ''} htmlFor="userEmail">User Email</label>
                        </div>
                        <div className="input-field col s12">
                            <input id="password" name="password" value={this.state.password} onChange={this.inputChange} type="password" required autoComplete='off' className="validate" />
                            <label className={this.state.password !== '' ? 'active' : ''} htmlFor="password">Password</label>
                        </div>
                    </div>
                    <button className="btn waves-effect waves-light FormBtn" type="submit" name="login">Login
                        <i className="material-icons right">login</i>
                    </button>
                </form>
    
            </div>
        )
    }   
}

export default Login;