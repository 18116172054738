import React from 'react';
import './scriptModal.css'

const ScriptModal = (props) => {
    return (
        <div id='breezetact-scripts' className="modal ScriptModal">
            <div className="modal-content">
            <h4>Breezetact Scripts</h4>
                <p id='scriptContent'>                   
                    {"<link href='https://breezetact.breezesuite.com/breezetact-scripts/static/css/breezetact.css' rel='stylesheet'>"}<br/>
                    {"<script>localStorage.setItem('comp_id',"+ props.compId +")</script>"}<br/>
                    {"<script src='https://breezetact.breezesuite.com/breezetact-scripts/static/js/breezetact-run.js'></script>"} <br/>
                    {"<script src='https://breezetact.breezesuite.com/breezetact-scripts/static/js/breezetact-main.js'></script>"} <br/>
                    {"<script src='https://breezetact.breezesuite.com/breezetact-scripts/static/js/breezetact-chunk.js'></script>"}
                </p>
            </div>
            <div className="modal-footer">
                <button className="btn modal-close waves-effect">Ok</button>
            </div>
        </div>
    )
}

export default ScriptModal;
