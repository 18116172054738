import React from 'react';
import M from 'materialize-css';

import './dashboard.css';
import CustomTable from '../customComponents/customTable/customTable';
import CustomForm from '../customComponents/customForm';
import Spinner from '../customComponents/spinner/spinner'
import ScriptModal from '../customComponents/scriptModal/scriptModal'
import {cacheHeaders, basePath} from '../config';

class DashBoard extends React.Component{

    state={
        listData:[],
        prevSelectedRow:0,
        currSelectedRow: 0,
        currSelectedName:'',
        columns:[
            {
              Header: "ID",
              accessor: "comp_id",
              filter: "text"
            },
            {
              Header: "Name",
              accessor: "comp_name",
              filter: "text"
            },
            {
                Header: "Background",
                accessor: "bg_color",
                filter: "text"
            },
            {
              Header: "Icon-color",
              accessor: "icon_color",
              filter: "text"
            },
            {
                Header: "Icons",
                accessor: "text",
                filter: "text"
            },
            {
                Header: "Icon-Links",
                accessor: "link",
                filter: "text"
            },
            {
                Header: "Script",
                accessor: "script",
                Cell: () => {
                    // console.log(cell.row.values.comp_id)
                    return  (<button className={"btn-floating waves-effect waves-light modal-trigger ctrlBtn"} data-target='breezetact-scripts'>
                                <i className="material-icons">code</i>
                            </button>)
                }
            }
        ],
        initialIcons:[],
        initialFormData:{
            comp_id:0,
            comp_name:'',
            bg_color:'',
            icon_color:'',
            show_text: true,
        },
        iconsList:[],
        availableIcons:[],
        formData:{
            comp_id:0,
            comp_name:'',
            bg_color:'',
            icon_color:'',
            show_text: true,
        },
        formType: 'Add',
        isLoading: true,
        hasError: false,
        isMenuOpen:false,
        isFormOpen: false
    }

    // loginRequired = ()=>{
    //     this.props.authe
    // }

    formOpenHandler = open => {
        this.setState({isFormOpen:open});
    }

    clearFormData = () =>{
        if(this.state.initialIcons.length === 0){
            fetch(basePath + '/dashboard/icons',{
                headers: { 
                    ...cacheHeaders,
                    'Authorization': 'Bearer '+ localStorage.getItem('authToken')
                 }
            })
            .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
            .then(data => {
                    this.setState({
                        initialIcons:data,
                        iconsList:data,
                        availableIcons:[],
                        formData:{...this.state.initialFormData},
                        formType:'Add',
                        prevSelectedRow:0,
                        currSelectedRow:0
                    });
                }
            )
            .catch(err => {
                if(+err.message && +err.message > 400 && +err.message < 500){
                    M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                    this.props.history.push('/login');
                }else{
                    M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
                }
            });
        }else{
            this.setState({
                formData:{...this.state.initialFormData},
                iconsList:[...this.state.initialIcons],
                availableIcons:[],
                formType:'Add',
                prevSelectedRow:0,
                currSelectedRow:0
            });
        }
    }

    toast = (data) =>{
        let classes=['rounded'];
        classes.push(data.success?'ToastSuccess':'ToastFailure');
        M.toast({html: data.message, classes: classes.join(' ')});
    } 

    rowSelectHandler = (id,name) => {
        if(id !== this.state.currSelectedRow){
            this.setState({currSelectedRow:id,currSelectedName:name});
        }
    }

    menuHandler = () => {
        this.setState((prevState) => 
            ({isMenuOpen: !prevState.isMenuOpen})
        )
    }

    addHandler = () => {
        this.clearFormData();
        this.formOpenHandler(true);
        this.menuHandler();
    }

    addIconToCompany = (icon) => {
        let updatedIconsList = [...this.state.availableIcons];
        updatedIconsList.push(icon);
        let selectableIcons = this.filterIcons(updatedIconsList,[...this.state.iconsList]);
        this.setState({iconsList:selectableIcons,availableIcons:updatedIconsList});
    }

    removeIconFromCompany = (icon) => {
        let updatedIconsList = [...this.state.iconsList];
        updatedIconsList.push(icon);
        let SelectedIcons = this.filterIcons(updatedIconsList,[...this.state.availableIcons]);
        this.setState({iconsList:updatedIconsList,availableIcons:SelectedIcons});
    }

    filterIcons = (refList,newList) => {
        newList = newList.filter(icon => {
            for(let i=0;i<refList.length;i++){
                if(icon.id === refList[i].id){
                    return false;
                }
            }
            return true;
        });
        return newList;
    }

    editHandler = () => {
        let id = this.state.currSelectedRow;
        let prevId = this.state.prevSelectedRow;
        this.clearFormData();
        // To prevent repeated API call for the same row
        if(id !== 0 && id !== prevId){
            fetch(basePath + '/dashboard/icons/'+id,{
                headers: { 
                    ...cacheHeaders,
                    'Authorization': 'Bearer '+ localStorage.getItem('authToken')
                 }
            })
            .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
            .then(data => {
                    const selectableIcons = this.filterIcons(data.icons,[...this.state.iconsList]);
                    this.setState({formData:data.company,iconsList:selectableIcons,availableIcons:data.icons,formType:'Edit',isFormOpen:true,isMenuOpen:false,prevSelectedRow:id});
                }
            )
            .catch(err => {
                if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
                }else{
                    M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
                }}); 
        }  
    }

    deleteHandler = () => {
        fetch(basePath + '/dashboard/'+ this.state.currSelectedRow,{
            method: 'delete',
            headers: {
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
            },
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            this.toast(data);
            this.setState({currSelectedRow:0,prevSelectedRow:0,currSelectedName:''});
            this.getCompanyDetails();
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
            }
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        const postData = {
            company: this.state.formData,
            icons: this.state.availableIcons
        }
        fetch(basePath + '/dashboard/',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
                ...cacheHeaders
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            this.toast(data);
            if(data.success){
                this.formOpenHandler(false);
                this.clearFormData();
                this.getCompanyDetails();
            }
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
            }
        });
    }

    inputChangeHandler = (index,event) =>{
        let value='';
        value = event.target.type === 'checkbox'? event.target.checked : event.target.value;
        if(index !== null){
            const iconData = [...this.state.availableIcons];
            let icon = {...iconData[index]};
            icon[event.target.name] = value;
            iconData[index] = icon;
            this.setState({availableIcons:iconData});
        }else{
            const formData = {...this.state.formData};
            formData[event.target.name] = value;
            this.setState({formData:formData});
        }      
    }

    getCompanyDetails = () => {
        this.setState({isLoading:true});
        fetch(basePath + '/dashboard',{
            headers: { 
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
             }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
                this.setState({listData:data,isLoading:false});
                // For auto completing Company Names
                let autocompleteData={};
                data.forEach(elem => {autocompleteData[elem.comp_name] = null;} );
                const elems = document.querySelectorAll('.autocomplete');
                const selectElems = document.querySelectorAll('select');
                M.Autocomplete.init(elems, {data:autocompleteData});
                M.FormSelect.init(selectElems);
            }
        )
        .catch(err => {
            M.AutoInit();
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An Error Occurred', classes: 'rounded ToastFailure'});
                this.setState({hasError:true});
            }
        });
    }

    componentDidMount(){
        M.AutoInit();
        this.getCompanyDetails();
    }

    render(){
        let isAdmin = this.props.role === 'admin';
        let formClasses=['FormDiv','card-panel'];
        if(this.state.isFormOpen){
            formClasses.push('FormOpen');
        }
        let menuClasses = ['ControlBar'];
        !this.state.isMenuOpen && menuClasses.push('MenuClosed')

        let content = <Spinner />
        if(!this.state.isLoading){
            if(this.state.hasError){
                content = <h3>Data Not Available</h3>
            }else{
                content = <CustomTable columns={this.state.columns} data={this.state.listData} select={this.rowSelectHandler} pagination filter exportable />
            }
        }
        return(
            <div className='DashBoard card-panel'>
                <div className='TableContainer'>
                    {content}
                </div>
                <div className='ControlPanel'>
                    <div>
                        <button className={"btn-floating btn-large pulse waves-effect cyan darken-3 waves-light"} onClick={this.menuHandler}>
                            <i className="material-icons large">{this.state.isMenuOpen?'close':'menu'}</i>
                        </button>
                    </div>
                    <div className={menuClasses.join(' ')}>
                        <button disabled={!isAdmin} className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='Add Company' onClick={this.addHandler}><i className="material-icons">add</i></button>
                        <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='View Report' disabled={this.state.currSelectedRow===0} 
                        onClick={() => {this.props.viewReport(this.state.currSelectedRow,this.state.currSelectedName);this.props.history.push('/dashboard/report')}} >
                                <i className="material-icons">visibility</i>
                        </button>
                        <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='Edit Company' disabled={this.state.currSelectedRow===0 || !isAdmin} onClick={this.editHandler} ><i className="material-icons">edit</i></button>
                        <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped modal-trigger" data-target='delete_modal' data-tooltip='Delete Company' onClick={this.menuHandler} disabled={this.state.currSelectedRow===0 || !isAdmin} ><i className="material-icons">delete</i></button>
                    </div>
                </div>
                <div className={formClasses.join(' ')}>
                    <div className='FormHeader'>
                        <span>{this.state.formType+' Company'}</span>
                        <i onClick={() => {this.formOpenHandler(false);this.clearFormData()}} className="material-icons">close</i>
                    </div>
                    <CustomForm
                        icons={this.state.iconsList}
                        formData={this.state.formData}
                        specificIcons={this.state.availableIcons}
                        addIcon = {this.addIconToCompany}
                        removeIcon = {this.removeIconFromCompany}
                        change={this.inputChangeHandler}
                        clear={this.clearFormData}
                        save={this.submitHandler} />
                </div>
                <div id="delete_modal" className="modal">
                    <div className="modal-content">
                    <h4>Delete Entity</h4>
                    <p>Are you sure you want to delete this company?<br/>
                        <b>{this.state.currSelectedRow + ' - ' + this.state.currSelectedName}</b>
                    </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn modal-close waves-effect waves-green" onClick={this.deleteHandler}>Yes</button>
                        <button className="btn modal-close waves-effect waves-red">No</button>
                    </div>
                </div>
                <ScriptModal compId={this.state.currSelectedRow} />    
            </div>
        )
    }
}

export default DashBoard;